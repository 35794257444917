import React, { useState, useEffect, useCallback } from 'react'
import { useParams, Link } from 'react-router-dom';
import incident_manager from '../api/incident';
import {RiderInjury} from './rider';
import {Cause} from './cause';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

// Required icons for our UI
// https://v4.mui.com/components/material-icons/

import FlagIcon from '@material-ui/icons/Flag';
import EventIcon from '@material-ui/icons/Event';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ScheduleIcon from '@material-ui/icons/Schedule';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import GroupIcon from '@material-ui/icons/Group';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DescriptionIcon from '@material-ui/icons/Description';
import NotesIcon from '@material-ui/icons/Notes';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LocationOnIcon from '@material-ui/icons/LocationOn'
import IncidentComments from './incident_comments';
import { hasPrivileges } from '../util/auth';
import IncidentMedia from './incident_media';
import { Button } from 'react-bootstrap';
import { removeIncidentCause, updateIncidentCauseDescription } from '../api/incidentCause';
import { Cancel, EditOutlined, Save } from '@material-ui/icons';

export default function Incident(props){
    let {id} = useParams();
    var [incident, setIncident] = useState(null);
    var [message, setMessage] = useState("");
    var [nextIncident, setNextIncident] = useState(null)
    var [previousIncident, setPreviousIncident] = useState(null)
    var [teamMode, setTeamMode] = useState(props&&props.location&&props.location.state?props.location.state.teamMode:false)

    var [incidentDescription, setIncidentDescription] = useState(null)
    var [descriptionEditable, setDescriptionEditable] = useState(false);

    var [incidentSummary, setIncidentSummary] = useState(null);
    var [summaryEditable, setSummaryEditable] = useState(false);
	
    var [incidentSeverity, setIncidentSeverity] = useState(null);
    var [severityEditable, setSeverityEditable] = useState(false);

    var [incidentRemainingDistance, setIncidentRemainingDistance] = useState(null)
    var [remainingDistanceEditable, setRemainingDistanceEditable] = useState(false)
	
	var [incidentNumberRiders, setIncidentNumberRiders] = useState(null);
    var [numberRidersEditable, setNumberRidersEditable] = useState(false);
	
	var [incidentLocationLatitude, setIncidentLocationLatitude] = useState(null);
    var [locationLatitudeEditable, setLocationLatitudeEditable] = useState(false);
	
	var [incidentLocationLongitude, setIncidentLocationLongitude] = useState(null);
    var [locationLongitudeEditable, setLocationLongitudeEditable] = useState(false);
	
	var [incidentType, setIncidentType] = useState(null);
    var [typeEditable, setTypeEditable] = useState(false);

    const setMessageTimeout = (msg)=>{
        setMessage(msg)
        setTimeout(()=>{
            setMessage("")
        }, 5000)
    }

    var load_incident = useCallback(()=>{
        incident_manager.get_incident(id).then((incident)=>{
            setIncident(incident)
            setIncidentDescription(incident.incident_description)
			setIncidentSummary(incident.incident_summary)
            setIncidentSeverity(incident.incident_severity)
			setIncidentNumberRiders(incident.incident_number_of_riders)
            setIncidentRemainingDistance(incident.incident_km_from_finish)
			setIncidentLocationLatitude(incident.incident_location_latitude)
			setIncidentLocationLongitude(incident.incident_location_longitude)
			setTypeEditable(incident.incident_type)
        }
        );
    },[id])
    
    var load_next_previous_incident = useCallback(() =>{
        console.log("Loading next previous incident");
        if(teamMode){
            incident_manager.get_team_incidents().then((response)=>processIncidents(response));
        }
        else{
            incident_manager.get_incidents().then((response)=>processIncidents(response));
        }
    }, [id])

    const processIncidents = (response)=>
        {
            var previous = null
            var incidents = Object.values(response)
            var i = 0;
            var stop = false;
            while(i<incidents.length && !stop){
                var incident=incidents[i]
                if(incident['incident_id']===parseInt(id)){
                    setPreviousIncident(previous)
                }
                else if(previous===parseInt(id)){
                    console.log("Found next");
                    setNextIncident(incident['incident_id'])
                    stop = true
                }
                previous = incident['incident_id']
                i+=1
            }
            if(i===incidents.length && !stop){
                setNextIncident(null)
            }  
        }

    const updateIncidentDescription = () => {
        incident_manager.update_incident_description(id, incidentDescription).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }
	
	const updateIncidentSummary = () => {
        incident_manager.update_incident_summary(id, incidentSummary).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }

    const updateIncidentSeverity = () =>{
        incident_manager.update_incident_severity(id, incidentSeverity).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }
	
	const updateIncidentType = () =>{
        incident_manager.update_incident_type(id, incidentType).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }
	
	const updateIncidentLocationLatitude = () =>{
		console.log("OK update latitude")
        incident_manager.update_incident_location_latitude(id, incidentLocationLatitude).then(val=>{
			console.log("OK update latitude")
            load_incident()
            load_next_previous_incident()
        });
    }
	
	const updateIncidentLocationLongitude = () =>{
        incident_manager.update_incident_location_longitude(id, incidentLocationLongitude).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }
	
	const updateIncidentNumberRiders = () =>{
		console.log(incidentNumberRiders);
		console.log(id);
        incident_manager.update_incident_number_of_riders(id, incidentNumberRiders).then(val=>{
            load_incident()
            load_next_previous_incident()
        });
    }

    useEffect(()=>{
        load_incident()
        load_next_previous_incident()
     },[id, load_incident, load_next_previous_incident])

    const updateCause = (causeId, incidentCauseDescription)=>{
        updateIncidentCauseDescription(id, causeId, incidentCauseDescription).then(()=>{
            load_incident();
        })
    }

    const updateImplications = (rider_id, implications)=>{
        var processedImplications = implications;
        if(implications==="No implications")
            processedImplications = ""
        incident_manager.update_incident_rider_implications(id, rider_id, processedImplications).then(()=>{
            load_incident();
            load_next_previous_incident();
        })
    }

    const updateInjury = (rider_id, injury)=>{
        incident_manager.update_incident_rider_injury(id, rider_id, injury).then(()=>{
            load_incident();
            load_next_previous_incident();
        })
    }
	
	const updateCard = (rider_id, card)=>{
        incident_manager.update_incident_rider_card(id, rider_id, card).then(()=>{
            load_incident();
            load_next_previous_incident();
        })
    }

    const updateRemainingDistance = () =>{
        incident_manager.update_incident_remaining_distance(id, incidentRemainingDistance).then(()=>{
            load_incident();
            load_next_previous_incident();
        })
    }

    var riderConfirmDialog = (incident_id, rider_id) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure you want to delete the rider '+rider_id+' from the incident '+incident_id+"?",
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  incident_manager.remove_rider_from_incident({'incident_id': incident_id, 'rider_id': rider_id}).then((res)=>{
                      setMessageTimeout("Successfully removed incident "+incident_id);
                      load_incident();
                  }).catch((err)=>{
                      setMessageTimeout("Error removing the rider "+rider_id+" from the incident.")
                  })
              }
            },
            {
              label: 'No',
              onClick: () =>{
                  setMessage("");
              }
            }
          ]
        });
      };

      var causeConfirmDialog = (cause_id) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure you want to delete the cause '+cause_id+' from the incident '+id+"?",
          buttons: [
            {
              label: 'Yes',
              onClick: () => {
                  removeIncidentCause(id, cause_id).then((res)=>{
                      setMessageTimeout("Successfully removed cause "+cause_id);
                      load_incident();
                  }).catch((err)=>{
                      setMessageTimeout("Error removing the cause "+cause_id+" from the incident.")
                  })
              }
            },
            {
              label: 'No',
              onClick: () =>{
                  setMessage("");
              }
            }
          ]
        });
      };
    
    if(incident){
        return (
            <div className='incidentViewer'>
                <h1>
                {previousIncident?<Link className='btn btn-nav float-left' to={`/incident/${previousIncident}`}><ArrowBackIosIcon fontSize='small'/> previous</Link>:("")}
                Incident (#{id})
                {nextIncident?<Link className='btn btn-nav float-right' to={`/incident/${nextIncident}`}><ArrowForwardIosIcon fontSize='small'/> Next</Link>:("")}</h1>
                {teamMode===true?<span class='badge'>You're currently only browsing your teams incidents</span>:""}
                <div className='message'>{message}</div>
                <div className='actionArea'>
                    {hasPrivileges("commissaire")?<Link className='btn btn-warning float-right' to={`/incident/${id}/edit`}>Edit incident</Link>:""}
                </div>
                <div className='incidentBrowser'>
                    {incident.stage && incident.stage.race_edition?(
                        <div className='raceInfo'>
                            <h3>Race information</h3>
                            <p className='raceName'><FlagIcon/>{incident.stage.race_edition.race.name} ({incident.stage.race_edition.uci_category} - {incident.stage.race_edition.uci_class})</p>
                            <p className='raceDate'><DateRangeIcon/>{incident.stage.race_edition.date_from} &rarr; {incident.stage.race_edition.date_to}</p>
                            <p className='raceStageDate'><EventIcon/>Stage date: {new Date(incident.stage.end_datetime).toDateString()}</p>
                            {incident.stage.stage_index>0?<p className='raceStageIndex'><EventIcon/>Stage number: {incident.stage.stage_index}</p>:""}
                        </div>
                        ):(
                            <div className='raceInfo'>
                                <p>No race info available.</p>
                            </div>
                        )
                    }
                
                    <h3>Incident information - {incident.incident_type}</h3>
                    <p className='incidentTimestamp'><ScheduleIcon/>{incident.incident_timestamp}</p>
                    <p className='incidentDistanceToGo'>
                        <LinearScaleIcon/>
                        {remainingDistanceEditable===true?
                            <div className='incidentRemainingDistanceEdit'>
                                <div className='incidentRemainingDistanceInput'>
                                    <input placeholder='Incident km from finish' id='incident_km_from_finish'
                                                className='form-control' type='number' 
                                                value={incidentRemainingDistance} name='incident_km_from_finish' 
                                                step="0.001" 
                                                onChange={(evt)=>{setIncidentRemainingDistance(parseFloat(evt.target.value))}}/>
                                    &nbsp; km from finishline &nbsp;
                                </div>
                                <div className='incidentRemainingDistanceActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateRemainingDistance();setRemainingDistanceEditable(false)}}><Save/></Button>
                                    &nbsp;
                                    <Button className='btn btn-secondary' onClick={()=>setRemainingDistanceEditable(false)}><Cancel/></Button>
                                </div>
                            </div>
                            :
                            <div className={hasPrivileges("commissaire")?'incidentRemainingDistanceHoverArea':''}>
                                <span className='incidentRemainingDistancePlaceholder' onClick={()=>{if(hasPrivileges("commissaire")===true){setRemainingDistanceEditable(true)}}}>
                                    {incidentRemainingDistance!==null?`${incidentRemainingDistance} km from finishline`:"remaining distance not set"}
                                </span>
                                &nbsp;
                                {hasPrivileges("commissaire")?<span className='incidentRemainingDistanceBtnArea'><Button className='btn btn-warning severityEditButton' onClick={()=>setRemainingDistanceEditable(true)}><EditOutlined/></Button></span>:""}
                            </div>
                        }
                    </p>	
					<p className='incidentLocationLatitude'>
						<LocationOnIcon/>	
						{incidentLocationLatitude!==null?
						<span>
						<a href={"https://maps.google.com/?q="+`${incidentLocationLatitude}`+","+`${incidentLocationLongitude}`} target="_blank">lat, lon:</a>&nbsp; 	
						</span>						
						:
						<span>
						lat, lon:&nbsp; 	
						</span>		
						}
						{locationLatitudeEditable===true?                        
                            <div className='incidentLocationLatitudeEdit'>
                                <div className='incidentLocationLatitudeInput'>
                                    <input type="number" step="any" className='form-control' value={incidentLocationLatitude} onChange={evt=>{setIncidentLocationLatitude(evt.target.value)}}/>
								</div>
                                <div className='incidentLocationLatitudeActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentLocationLatitude();setLocationLatitudeEditable(false)}}><Save/></Button>
                                    <Button className='btn btn-secondary' onClick={()=>setLocationLatitudeEditable(false)}><Cancel/></Button>
                                </div>
                            </div>
							:
                            <div className={hasPrivileges("commissaire")?'incidentLocationLatitudeHoverArea':''}>
                                <span className='incidentLocationLatitudePlaceholder' onClick={()=>{if(hasPrivileges("commissaire")===true){setLocationLatitudeEditable(true)}}}>
									{incidentLocationLatitude!==null?`${incidentLocationLatitude}`:"not set"}
									</span>
								&nbsp;
                                {hasPrivileges("commissaire")?<span className='incidentLocationLatitudeBtnArea'><Button className='btn btn-warning locationLatitudeEditButton' onClick={()=>setLocationLatitudeEditable(true)}><EditOutlined/></Button></span>:""}
                            </div>
						}
                    ,&nbsp; 
						{locationLongitudeEditable===true?                        
                            <div className='incidentLocationLongitudeEdit'>
                                <div className='incidentLocationLongitudeInput'>
                                    <input type="number" step="any" className='form-control' value={incidentLocationLongitude} onChange={evt=>{setIncidentLocationLongitude(evt.target.value)}}/>
								</div>
                                <div className='incidentLocationLongitudeActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentLocationLongitude();setLocationLongitudeEditable(false)}}><Save/></Button>
                                    <Button className='btn btn-secondary' onClick={()=>setLocationLongitudeEditable(false)}><Cancel/></Button>
                                </div>
                            </div>
							:
                            <div className={hasPrivileges("commissaire")?'incidentLocationLongitudeHoverArea':''}>
                                <span className='incidentLocationLongitudePlaceholder' onClick={()=>{if(hasPrivileges("commissaire")===true){setLocationLongitudeEditable(true)}}}>
									{incidentLocationLongitude!==null?`${incidentLocationLongitude}`:"not set"}
									</span>
								&nbsp;
                                {hasPrivileges("commissaire")?<span className='incidentLocationLongitudeBtnArea'><Button className='btn btn-warning locationLongitudeEditButton' onClick={()=>setLocationLongitudeEditable(true)}><EditOutlined/></Button></span>:""}
                            </div>
						}
                    </p>					
                    <p className='incidentSeverity'>
                        <TrendingUpIcon/>Severity : &nbsp; 
                        {hasPrivileges("commissaire")?severityEditable?
                            <div className='incidentSeverityEdit'>
                                <div className='incidentSeverityInput'>
                                    <input placeholder='Incident severity' id='incident_severity'
                                        name="incident_severity"
                                        value={incidentSeverity} 
                                        step='1' 
                                        onChange={(evt)=>setIncidentSeverity(parseInt(evt.target.value))}
                                        className='custom-range' type='range'
                                        min='0' max='10'/>
                                    &nbsp;<span className="badge badge-info">{incidentSeverity}</span>&nbsp;
                                </div>
                                <div className='incidentSeverityActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentSeverity();setSeverityEditable(false)}}><Save/></Button>
                                    &nbsp;
                                    <Button className='btn btn-secondary' onClick={()=>setSeverityEditable(false)}><Cancel/></Button>
                                </div>
                            </div>
                        :
                        <div className='incidentSeverityHoverArea'>
                            <span className='incidentSeverityPlaceHolder' onClick={()=>setSeverityEditable(true)}>{incidentSeverity}/10</span>
                            &nbsp;
                            <span className='severityBtnArea'><Button className='btn btn-warning severityEditButton' onClick={()=>setSeverityEditable(true)}><EditOutlined/></Button></span>
                        </div>
                        :`${incidentSeverity}/10`}
                    </p>
					<p className='incidentNumberRiders'>
                        <GroupIcon/>Number of involved riders : &nbsp; 
                        {hasPrivileges("commissaire")?numberRidersEditable?
                            <div className='incidentNumberRidersEdit'>
                                <div className='incidentNumberRidersInput'>
                                    <input placeholder='Incident numberRiders' id='incident_number_of_riders'
                                        name="incident_number_of_riders"
                                        value={incidentNumberRiders} 
                                        step='1' 
                                        onChange={(evt)=>setIncidentNumberRiders(parseInt(evt.target.value))}
                                        className='custom-range' type='range'
                                        min='0' max='50'/>
                                    &nbsp;<span className="badge badge-info">{incidentNumberRiders}</span>&nbsp;
                                </div>
                                <div className='incidentNumberRidersActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentNumberRiders();setNumberRidersEditable(false)}}><Save/></Button>
                                    &nbsp;
                                    <Button className='btn btn-secondary' onClick={()=>setNumberRidersEditable(false)}><Cancel/></Button>
                                </div>
                            </div>
                        :
                        <div className='incidentNumberRidersHoverArea'>
                            <span className='incidentNumberRidersPlaceHolder' onClick={()=>setNumberRidersEditable(true)}>{incidentNumberRiders}</span>
                            &nbsp;
                            <span className='numberRidersBtnArea'><Button className='btn btn-warning numberRidersEditButton' onClick={()=>setNumberRidersEditable(true)}><EditOutlined/></Button></span>
                        </div>
                        :`${incidentNumberRiders}`}
                    </p>
                    <p className='incidentDescription'>
                        {hasPrivileges("commissaire")?descriptionEditable?
                            <div className='incidentDescriptionEdit'>
                                <div className='incidentDescriptionInput'>
                                    <DescriptionIcon/><textarea className='form-control' value={incidentDescription} onChange={evt=>{setIncidentDescription(evt.target.value)}}/>
                                </div>
                                <div className='incidentDescriptionActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentDescription();setDescriptionEditable(false)}}><Save/></Button>
                                    <Button className='btn btn-secondary' onClick={()=>setDescriptionEditable(false)}><Cancel/></Button>
                                </div>
                            </div>:
                            <div className='incidentDescriptionHoverArea'>
                                <span className='incidentDescriptionPlaceholder' onClick={()=>setDescriptionEditable(true)}><DescriptionIcon/>{incidentDescription}</span>
                                <span className='descriptionBtnArea'><Button className='btn btn-warning descriptionEditButton' onClick={()=>setDescriptionEditable(true)}><EditOutlined/></Button></span>
                            </div>
                        :<span><DescriptionIcon/>{incidentDescription}</span>}
                    </p>
					<p className='incidentSummary'>
                        {hasPrivileges("commissaire")?summaryEditable?
                            <div className='incidentSummaryEdit'>
                                <div className='incidentSummaryInput'>
                                    <NotesIcon/><textarea className='form-control' value={incidentSummary} onChange={evt=>{setIncidentSummary(evt.target.value)}}/>
                                </div>
                                <div className='incidentSummaryActions'>
                                    <Button className='btn btn-success' onClick={()=>{updateIncidentSummary();setSummaryEditable(false)}}><Save/></Button>
                                    <Button className='btn btn-secondary' onClick={()=>setSummaryEditable(false)}><Cancel/></Button>
                                </div>
                            </div>:
                            <div className='incidentSummaryHoverArea'>
                                <span className='incidentSummaryPlaceholder' onClick={()=>setSummaryEditable(true)}><NotesIcon/>{incidentSummary}</span>
                                <span className='summaryBtnArea'><Button className='btn btn-warning summaryEditButton' onClick={()=>setSummaryEditable(true)}><EditOutlined/></Button></span>
                            </div>
                        :<span><NotesIcon/>{incidentSummary}</span>}
                    </p>
                    <h3 key='incident_causes'>Incident causes</h3>
                    {Object.values(incident.causes).length>0?(<table className='incidentCauses' key='incidentCauses'>
                        <thead>
                            <tr className='incidentCause header' key='header_row'>
                                <th className='causeDescription header' key='causeDescription'><b>Cause</b></th>
                                <th className='incidentCauseDescription header' key='incidentCauseDescription'><b>Incident cause description</b></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(incident.causes).map(incident_cause=>{
                                return (
                                    <Cause cause={incident_cause} key={incident_cause.cause_id} updateCause={updateCause} onDelete={causeConfirmDialog}/>
                                )
                            })}
                        </tbody>   
                    </table>):(<p><i>No causes attached yet to this incident</i></p>)}
                    <h3 key='involved_riders'>Involved riders</h3>
                    {Object.values(incident.riders).length>0?(<table className='involvedRiders' key='involvedRiders'>
                        <thead>
                            <tr className='incidentRider header' key='header_row'>
                                <th className='riderName header' key='header_rider_name'><b>Rider name</b></th>
                                <th className='riderUciId header' key='header_rider_uciid'><b>Rider UCI id</b></th>
                                <th className='riderTeam header' key='header_rider_team'><b>Rider team</b></th>
                                <th className='riderInjuryType header' key='header_injury_type'><b>Injury type</b></th>
                                <th className='riderInjuryImplications header' key='header_injury_implications'><b>Injury implications</b></th>
								<th className='riderInjuryCard header' key='header_injury_card'><b>Sanction</b></th>
                                {hasPrivileges('commissaire')?<th className='riderInjuryActions' key='header_injury_actions'>Actions</th>:""}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(incident.riders).map((incident_rider,i)=>{
                                console.log(incident_rider);
                                return (
                                    <RiderInjury rider={incident_rider} key={`incident_${incident.id}_rider_${incident_rider.rider.id}`} updateImplications={updateImplications} updateInjury={updateInjury} updateCard={updateCard} deleteHandler={()=>{riderConfirmDialog(incident.incident_id, incident_rider.rider.rider_id)}}/>
                                )
                            })}
                        </tbody>   
                    </table>):(<p><i>No riders attached yet to this incident.</i></p>)}
                    <IncidentMedia incidentId={id} setMessage={setMessageTimeout}/>
                    {hasPrivileges('staff')?<IncidentComments incidentId={id} setMessage={setMessageTimeout}/>:""}

                </div>
            </div>
        )
    }
    else{
        return (
            <div className='incidentViewer' key='loader-viewer'>
                <h1> Incident viewer </h1>
                Loading...
            </div>
        )
    }

}